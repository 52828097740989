import api from '@/api/api';
import Response from '../classes/Response';

const host = process.env.VUE_APP_CCAPI
const business = process.env.VUE_APP_BUSINESS_FONCABSA
const hostMultiApi = process.env.VUE_APP_MULTIAPI;
export default class Address {

    #street = "";
    #suburb = "";
    #ext_number = "";
    #int_number = "";
    #postal_code = "";
    #between_street_one = "";
    #between_street_two = "";
    #city = "";
    #state = "";
    #country = "";
    #municipality = "";

    constructor( { street, suburb, ext_number, int_number, postal_code, between_street_one, between_street_two, city, state, country, municipality }) {

        this.#street = street;
        this.#suburb = suburb;
        this.#ext_number = ext_number;
        this.#int_number = int_number;
        this.#postal_code = postal_code;
        this.#between_street_one = between_street_one;
        this.#between_street_two = between_street_two;
        this.#city = city;
        this.#state = state;
        this.#country = country;
        this.#municipality = municipality;
    }

    get_address = () =>
    {
        return `${this.#street} #${this.#ext_number}, ${this.#suburb}`
    }

    getCountries = async (token) => {
        const resource = `/${business}/catalog_country/get_all?token_auth=${token}`;
        const API_URL = `${host}${resource}`;

        const config = {
            headers: {
                "Content-Type": "application/json",
            }
        };

        const params = { withCredentials: true, token_auth: token };

        return await api.httpGet(API_URL, { params, config }).catch(err => { return err });
    }

    getStates = async (token) => {
        const resource = `/Api/Catalogo/Sepomex/obtener_estados?token_auth=${token}`;
        const API_URL = `${hostMultiApi}${resource}`;

        const config = {
            withCredentials: true,
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
            }
        };

        const auth = {
            auth: {
                username: "multicr",
                password: "*cabsa2018*"
            }
        };

        return await api.httpPost(API_URL, auth, config).catch(err => { return err });
    }

    getMunicipalities = async (token, state) => {
        const resource = `/Api/Catalogo/Sepomex/obtener_municipios_por_estado?token_auth=${token}`;
        const API_URL = `${hostMultiApi}${resource}`;

        const config = {
            contenido: {
                estado: state,
            },
            withCredentials: true,
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
            }
        };

        const auth = {
            auth: {
                username: "multicr",
                password: "*cabsa2018*"
            }
        };

        return await api.httpPost(API_URL, auth, config).catch(err => { return err });
    }

    getCities = async (token, state, municipality) => {
        const resource = `/Api/Catalogo/Sepomex/obtener_ciudades_por_estado_y_municipio?token_auth=${token}`;
        const API_URL = `${hostMultiApi}${resource}`;

        const config = {
            contenido: {
                estado: state,
                municipio: municipality
            },
            withCredentials: true,
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
            }
        };

        const auth = {
            auth: {
                username: "multicr",
                password: "*cabsa2018*"
            }
        };

        return await api.httpPost(API_URL, auth, config).catch(err => { return err });
    }

    getColonies = async (postalCode) => {
        const resource = `/Api/Catalogo/Sepomex/obtener_colonias`;
        const API_URL = `${hostMultiApi}${resource}`;
        const config = {
            contenido: {
                codigo_postal: postalCode,
                colonia: ""
            },
            withCredentials: true,
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
            }
        };

        const auth = {
            auth: {
                username: "multicr",
                password: "*cabsa2018*"
            }
        };

        return await api.httpPost(API_URL, auth, config).catch(err => { return err });

    }

    getColonies = async (postalCode) => {

            const resource = `/Api/Catalogo/Sepomex/obtener_colonias`;
            const API_URL = `${hostMultiApi}${resource}`;
            
            const config = {
                 contenido: {
                    codigo_postal : postalCode,
                    colonia : ""
                },
            
                 withCredentials: true,
                 headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
            }
        };
        
            const auth = {       
                auth: {
                username: "multicr",
                password: "*cabsa2018*"
            }
            
        };
            return await api.httpPost(API_URL, auth, config).catch(err=> {return err});
    }

    update = async ( { client_id } ) =>
    {
        try 
        {
            const resource = `${host}/${business}/clients/update_address`;

            const params = {
                "token_auth": sessionStorage.getItem("login"),
                client_id: client_id,
                address:{
                    city:this.#city,
                    country:this.#country,
                    ext_number:this.#ext_number,
                    municipality:this.#municipality,
                    postal_code:this.#postal_code ,
                    state:this.#state,
                    street:this.#street,
                    suburb: this.#suburb,
                    between_street_one:this.#between_street_one, 
                    between_street_two:this.#between_street_two,
                    int_number:this.#int_number
                }
              };
        
              const config = {
                headers: {
                  "Content-Type": "application/json",
                }
              };
            const response =  await api.httpPost(resource, config, params);
            const _response = new Response( { data: response.data } );
            _response.validate();
            return { status:false};
            
        } catch (error) {
            console.log(ex)
            const _response = new Response( { data: response.data } );
            _response.assing_messaje();
            _response.status = false;
            
            return _response;
        }
    }


}
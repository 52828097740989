<template>
    <Loading :isLoading="isLoading" />
    <div class="bg-white m-0 px-5 md:px-3 md:m-5 ">
        <div class="grid grid-cols-1 md:grid-cols-2 px-0 md:px-0 lg:px-20 mt-10">

            <div class="w-full md:w-4/5 md:mx-auto mt-5">
                <p class="text-3xl font-bold roboto-regular mb-5">{{ questionTitle }}</p>
                <p v-if="questionTitle.toString().toLowerCase() == 'beneficiarios'"  class="text-md font-bold roboto-regular mb-10 text-gray-600">Completa los datos de los Beneficiarios hasta cumplir con el 100% de beneficio:</p>
                <Suspense>
                    <template #default>
                        <div :class="{'shadow p-5': questionTitle.toString().toLowerCase() == 'beneficiarios'}">
                            <p v-if="questionTitle.toString().toLowerCase() == 'beneficiarios'" class="roboto-regular mb-5 text-xl">Datos de beneficiario</p>
                            <ComponentsPersonalData 
                                :key="reRender"
                                class="h-auto"
                                :listQuestionPersonal="listOfQuestionPeronalInfo" ref="ComponentsPersonalDataRef" 
                                v-on:return-personal-data="changeModelPersonalData($event)" :classContainer="'flex flex-col w-full mt-5'" :rulesValidationPersonal="validations" :modelPersonal="vidaSimpleModel"                            
                                :ClassInputsQuestion="'input-question w-full p-2 outline-transparent ring-transparent focus:outline-none border-2 focus:ring focus:border-cherryColor active:border-cherryColor rounded-sm w-full mt-2'"
                                :ClassContainerQuestion="'question-container w-full font-light'"
                                :ShowPlasceholderQuestion="false"
                                :ClassErrorsContainerQuestion="'mt-2 flex flex-col'"
                                :ClassErrorsTextQuestion="'text-red-700 text-sm '"
                            />
                        </div>
                    </template>
                    <template #fallback>
                        <p>Cargando...</p>
                    </template>
                </Suspense>

                <BeneficiariesDetail v-if="questionTitle.toString().toLowerCase() == 'beneficiarios'" :enableAddButton="btnAddActive" :beneficiaries="beneficiariesList" @addBeneficiary="addBeneficiaryList()" @delete-beneficiary="deleteBeneficiaryList($event)" />

                <div class="w-full flex justify-center">
                   <input type="button" :disabled="!isActive" :class="{'button-disabled cursor-default': !isActive, 'bg-mustardDark cursor-pointer': isActive}" class="w-full text-white px-5 py-2 mx-auto my-4 h-14 md:h-14  rounded text-center font-medium text-xl" @click="next()" value="Continuar">        
                </div>
            </div>
            <div class="w-full bg-white hidden md:block md:py-14">
                <img :src="imageCategory" :alt="questionTitle" class="mx-auto" >
            </div>
        </div> 
    </div>
</template>

<script>
import { ref, toRef, onMounted, onBeforeMount, watch } from 'vue';
import { helpers, requiredIf, minValue, minLength, maxLength, numeric, alphaNum, alpha } from '@vuelidate/validators';
import Products from '@/classes/Products.js';
import ValidationsStructure from '@/vuelidate_rules/VidaSimple/VidaSimple.json';
import Document from '@/classes/Document'
import validationsStructure from '@/helpers/validations';
import BeneficiariesDetail from '@/components/Cuenta/Quote/BeneficiariesDetail';

import Swal from 'sweetalert2';
import Loading from '@/components/Loading/VueLoading.vue';
import ComponentsPersonalData from 'components-personal-data';
import { useRouter } from 'vue-router';
import Address from "@/classes/Address"
import PolicyIssuance from "@/classes/PolicyIssuance"
import moment from "moment";
import { get } from 'axios'


export default {
    props:{
        category: {
            type: String,
            default: () => "personal-data"
        },
        lastPage: {
            type: Number,
            default: 0
        }
    },
    setup(props){
        const policyIssuanceClass = new PolicyIssuance();
        const tokenAuthorization = ref(null);
        const isLoading = ref(true);
        const reRender = ref(null);
        const currentCategory = ref('');
        const ComponentsPersonalDataRef = ref(null);
        const addressClass = ref(null);

        const questionsList = ref([]);
        const allQuestionsList = ref(null);
        const indexCategory =  ref(0);
        const category = ref('');

        const userInfo = ref(null);
        const validations = ref({});
        const products = new Products();
        const goBack = toRef(props, 'lastPage')

        let questionTitle = ref('');
        let vidaSimpleValidations = ref({});
        let vidaSimpleModel = ref({});
        let validationRules = ref({});
        let isActive = ref(false);
        let imageCategory = ref('');
        let data = ref([]);
        let quoteData = ref([]);
        let beneficiariesList = ref([]);
        let btnAddActive = ref(true);
        const router = useRouter();
        const documentObjectClass = ref(null);

        watch(goBack, (val)=> {
            if(val>0){
                back();
            }else if(indexCategory.value){
                console.log("Aun no hay pagina hacia atras", val);
            }
        });


        onBeforeMount(async()=> {
            let existQuoteData = storageExist('quote-data');
            let existQuoteForm = storageExist('quote');
            if(!existQuoteForm || !existQuoteData){
                router.push({path: '/cuenta/detalle/vida-simple'})
            }

            indexCategory.value = questionListFlow();
            getValidationsRules();
        });

        onMounted(async()=>{

            addressClass.value = new Address();
            documentObjectClass.value = new Document();

            if(props.category != 'personal-data'){
                await getQuestions();
                tokenAuthorization.value = await getTokenAuthorization();
                validations.value = vidaSimpleValidations;
                reRender.value = true;
            }

            isLoading.value = false;
        });

        const getTokenAuthorization = async () => {
            return await policyIssuanceClass.getToken().then(resp => resp.data.response.token_auth);
        }

        const questionListFlow = () => {
            let quotationAnswers = getStorage('quote-data');
            if(quotationAnswers.contracting_data.contractor_other_than_the_applicant == 'no'){
                return 4;
            }else{
                return 2;
            }
        }

        const getValidationsRules = async () => {  
            validationRules.value = await validationsStructure.getStructureQuote('validationsIssuance.json')
            validationRules.value = validationRules.value.data;
        }

        const getQuestions = async () => {
            
            const response = await products.getQuotingList(sessionStorage.getItem("login")).then(resp => resp.data.response.response ).catch(err => { 
                if(err.response.status != 500) {
                    Swal.fire({
                        title: "Aviso",
                        html: err.response.data.response.message,
                        icon: "info",
                        confirmButtonText: "OK",
                        confirmButtonColor: '#FEB72B'
                    });
                }else{
                    Swal.fire({
                        title: "Aviso",
                        html: "Servicio de cotización no disponible, por favor inténtelo de nuevo más tarde",
                        icon: "info",
                        confirmButtonText: "OK",
                        confirmButtonColor: '#FEB72B'
                    });
                }

                return null;
            });
            
            if(response == null){
                router.push({path: '/cuenta/detalle/vida-simple'})
                return;
            }
            
            let inProgress = getCategoryInformation(response.order_question_category, true);
            if(inProgress.status){
                indexCategory.value = inProgress.position;
                loadStorageBeneficiaries();
            }

            let questionList = [];
            
            currentCategory.value = (inProgress.status)?inProgress.category.node_key :response.order_question_category[indexCategory.value].node_key;
            questionTitle.value = (inProgress.status)?inProgress.category.title :response.order_question_category[indexCategory.value].title;

            allQuestionsList.value = response;
            questionList = (inProgress.status)? inProgress.questions :response.questions[currentCategory.value];
            
            questionList = setOptionType(questionList, !inProgress.status);
            
            questionsList.value = questionList;

            getValidations(currentCategory.value);
            getImageCategory(currentCategory.value);
        }

        const setOptionType = (questions, updateResponse = false) => {
            questions.forEach( question => {
                question.errors = [];
                if(updateResponse){
                    question.response = (question.question_type == 'textarea' || question.question_type == 'checkbox' || question.question_type == 'file')? [] : (question.question_type == 'number')? 0 : '';
                }
                question.is_touched = false;
            });

            return questions;
        }

        const getCategoryInformation = (categories, firstCategory) => {
            try{
                if(storageExist('issuance')){//Si existe un storage obtendremos los datos y los igualaremos segun el tipo de input
                    let issuance = getStorage('issuance');
                    const activeCategories = categories.filter(category => issuance[category.node_key] != undefined)
                    let i = Object.keys(activeCategories);
                    

                    if(firstCategory){
                        i = i.shift(); 
                    }else{
                        i = i.pop();
                    }

                    return {
                        status: true,
                        position: parseInt(i),
                        category: activeCategories[i],
                        questions: issuance[activeCategories[i].node_key]
                    }
                }else{
                    return {
                        status: false,
                        position: 0
                    }
                }
            }catch(err){
                console.log("Error getCategoryInformation:", err)
                return {
                    status: false,
                    position: 0
                }
            }
        } 

        const getImageCategory = (category) => {
            imageCategory.value = require(`@/assets/Cuenta/vidaSimple/${category}.svg`);
        }

        const next = ()   => {
            if(!isActive.value){
                return;
            }

            //Beneficiarios
            if(questionTitle.value.toString().toLowerCase() == 'beneficiarios'){
                let isValid = isValidToAddBeneficiaries();
                if(isValid.status){
                    Swal.fire({
                        title: "Aviso",
                        html: 'El porcentaje total asignado a los beneficiarios debe ser del 100%, verifique que la proporción del beneficio sea correcta',
                        icon: "info",
                        confirmButtonText: "OK",
                        confirmButtonColor: '#FEB72B'
                    });
                    return;
                }
            }

            //Redireccionamiento
            /* if(indexCategory.value >= allQuestionsList.value.order_question_category.length-1){
                router.push({path:`/cuenta/emision/vida-simple/detalle`});
                return;
            } */

            if(indexCategory.value >= allQuestionsList.value.order_question_category.length-2){
                router.push({path:"/cuenta/emision/vida-simple/carga-documentos"});
                return;
            }

            //Navegacion con excepciones
            let navigation = skipCategory();
            if(navigation.status){
                indexCategory.value = navigation.data.next;
            }else{
                indexCategory.value++;//Flujo normal
            }

            
            currentCategory.value = allQuestionsList.value.order_question_category[indexCategory.value].node_key;
            questionTitle.value = allQuestionsList.value.order_question_category[indexCategory.value].title;

            let issuanceExist = storageExist('issuance');
            let issuanceCategoryExist = {};
            if (issuanceExist) {
                let issuance = getStorage('issuance');
                issuanceCategoryExist = issuance.hasOwnProperty(currentCategory.value);
            }

            // Validar si existe en el local storage o si no existe el nodo en el localStorage
            if (!issuanceCategoryExist){ 
                getValidations(currentCategory.value);
                
                questionsList.value = allQuestionsList.value.questions[currentCategory.value];
                questionsList.value = setOptionType(questionsList.value, true);  
            } else {
                getValidations(currentCategory.value);
                setData();
            }
            
            reRender.value = !reRender.value;
            getImageCategory(currentCategory.value);
            isActive.value = false;
        }

        const skipCategory = () => {
            try{
                
                let nav = {
                    status: false,
                    next: 0,
                    back: 0
                };

                if(questionTitle.value.toString().toLowerCase() ==  'datos de contratación'){
                    nav.status = true;
                    let quoteData =  getStorage('quote-data');
                    if(quoteData.contracting_data.contractor_other_than_the_applicant == 'no'){
                        nav.next = 6;
                        nav.back = 0;
                    }else{
                        nav.next = 4;
                        nav.back = 0;
                    }

                    return {
                        status: true,
                        data: nav,
                        message: 'Navegacion con excepcion encontrada'
                    }
                }

                let exist = storageExist('issuance-data');
                if(!exist){
                    throw 'No existe storage con respuestas del formulario';
                    return
                }
                
                let answers = getStorage('issuance-data');

                if(questionTitle.value.toString().toLowerCase() ==  'dirección del solicitante'){
                    nav.status = true;
                    let quoteData =  getStorage('quote-data');
                    if(quoteData.contracting_data.contractor_other_than_the_applicant == 'si'){
                        nav.next = 3;
                        nav.back = 0;
                    }
                }

                if(questionTitle.value.toString().toLowerCase() ==  'datos laborales del solicitante'){
                    nav.status = true;
                    let quoteData =  getStorage('quote-data');
                    if(quoteData.contracting_data.contractor_other_than_the_applicant == 'si'){
                        nav.next = 4;
                        nav.back = 2;
                    }
                    
                }

                if(questionTitle.value.toString().toLowerCase() ==  'cobranza'){
                    nav.status = true;
                    let quoteData =  getStorage('quote-data');
                    if(quoteData.contracting_data.contractor_other_than_the_applicant == 'si'){
                        nav.next = 5;
                        nav.back = 3;
                    }else{
                        nav.next = 5;
                        nav.back = 0;
                    }
                }

                if(questionTitle.value.toString().toLowerCase() ==  'beneficiarios'){
                    nav.status = true;
                    let quoteData =  getStorage('quote-data');
                    if(quoteData.contracting_data.contractor_other_than_the_applicant == 'si'){
                        nav.next = 6;
                        nav.back = 4;
                    }else{
                        nav.next = 6;
                        nav.back = 4;
                    }
                    
                }

                if(questionTitle.value.toString().toLowerCase() ==  'documentos'){
                    nav.status = true;
                    nav.next = 9;
                    nav.back = 7;
                }
                
                if(!nav.status){
                    throw 'Sin excepcion de navegacion';
                }

                return {
                    status: true,
                    data: nav,
                    message: 'Navegacion con excepcion encontrada'
                }
            }catch(err){
                return {
                    status: false,
                    data: null,
                    message: err
                }
            }

        }

        const back = ()   => {
            
            let quoteData =  getStorage('quote-data');
            if((quoteData.contracting_data.contractor_other_than_the_applicant == 'si' && indexCategory.value == 2) || (quoteData.contracting_data.contractor_other_than_the_applicant == 'no' && indexCategory.value == 4) || indexCategory.value == 0){
                router.push({path: `/cuenta/cotizacion/vida-simple/finalizar`})
                return;
            }
            
            //Navegacion con excepciones
            let navigation = skipCategory();
            if(navigation.status){
                indexCategory.value = navigation.data.back;
            }else{
                indexCategory.value--;//Flujo normal
            }
            
            currentCategory.value = allQuestionsList.value.order_question_category[indexCategory.value].node_key;
            questionTitle.value = allQuestionsList.value.order_question_category[indexCategory.value].title;
            
            getValidations(currentCategory.value);    
            reRender.value = !reRender.value;

            setData();
            getImageCategory(currentCategory.value);
        }
        
        const changeModelPersonalData = (values) => {
            userInfo.value = values;
            let issuanceExist = storageExist('issuance');
            
            //createStorage('issuance', {});
            if( !issuanceExist ) {//Si no existe el localstorage
                data.value[currentCategory.value] = questionsList.value;
                createStorage('issuance', data);
            } else {// Si existe el localstorage se actualizara el nodo actual

                if(!isEmptyObject(userInfo.value)) {
                    const quote = getStorage('issuance');
                    quote[currentCategory.value] = questionsList.value;
                    createStorage('issuance', quote);
                }
            }

            let existQuoteData = storageExist('issuance-data');
            //createStorage('issuance-data', {});
            if (!existQuoteData) {
                quoteData.value[currentCategory.value] = vidaSimpleModel.value;
                createStorage('issuance-data', quoteData);
            }else{
                if(!isEmptyObject(userInfo.value)) {
                    const quoteDetail = getStorage('issuance-data');
                    quoteDetail[currentCategory.value] = vidaSimpleModel.value;
                    createStorage('issuance-data', quoteDetail);
                }
            }
            
            isActive.value = !isEmptyObject(userInfo.value);
        }

        const loadFiles = async (files) => {
            let issuance = getStorage('issuance');
            let documents = issuance;

            if(Object.keys(files).length==0){
                console.log("Sin documentos:");
                return;
            }

            if(files.ine_if_on_both_sides_documents !== undefined && !isEmptyObject(files.ine_if_on_both_sides_documents)){
                let imgIne = await convertBase64(files.ine_if_on_both_sides_documents[0]);

                /* if(imgIne.status){
                    documents.documents[0].response.push(imgIne.data);
                    console.log("documents.documents[0].response:", documents.documents[0].response);
                } */
            }

            if(files.proof_of_address_documents !== undefined && !isEmptyObject(files.proof_of_address_documents)){
                let imgAddress = await convertBase64(files.proof_of_address_documents[1]);
                
                /* if(imgAddress.status){
                    documents.documents[0].response.push(imgAddress.data);
                    console.log("documents.documents[0].response:", documents.documents[0].response);
                } */
            }
        }

        function isEmptyObject(obj) {
            return Object.keys(obj).length === 0;
        }

        const convertBase64 = async (image) => {
            try{
                // Encode the file using the FileReader API
                let imageBase64 = await documentObjectClass.value.serialize(image).then(base64Response => {
                    return base64Response;
                }).catch(err => {
                    console.log("Error:", err);
                });

                return {
                    status: true,
                    data: imageBase64,
                    message: 'Imagen convertida con exíto'
                }
            }catch(err){
                console.log("convertBase64 error:", err);
                return {
                    status: false,
                    data: '',
                    message: err
                }
            }
            
        }

        const setData = () => {
            let issuanceQuestions = getStorage('issuance');
            questionsList.value = issuanceQuestions[currentCategory.value];
        }

        const createStorage = (name, value) => {
            localStorage.setItem(name, JSON.stringify(value));
        }
        
        const getStorage = (name) => {
            return JSON.parse(localStorage.getItem(name));
        }

        const storageExist = (name) => {
            return (localStorage.getItem(name))? true: false;
        }

        function isEmptyObject(obj) {
            return Object.keys(obj).length === 0;
        }

        const getValidations =  (category) => {    
            let validations = {};
            let model = {};
            
            if (category == "address_of_contracting") {
                validations = {
                    suburb_address_of_contracting: {
                        required: helpers.withMessage("El campo es requerido", requiredIf(true))
                    },
                    street_address_of_contracting:{
                        required: helpers.withMessage(ValidationsStructure.diseases.suffer_from_disease.required.message, requiredIf(false)),
                        minLength: helpers.withMessage("El campo debe contener mínimo 2 caracteres.", minLength(2)),
                        maxLength: helpers.withMessage("El campo debe contener máximo 100 caracteres.", maxLength(100)),
                    },
                    no_ext_address_of_contracting:{
                        required: helpers.withMessage(ValidationsStructure.diseases.suffer_from_disease.required.message, requiredIf(false)),
                        minLength: helpers.withMessage("El campo debe contener mínimo 1 dígito.", minLength(1)),
                        maxLength: helpers.withMessage("El campo debe contener máximo 5 dígitos.", maxLength(5)),
                        numeric: helpers.withMessage("Solo se permiten números enteros", numeric )
                    },
                    no_int_address_of_contracting:{
                        required: helpers.withMessage(ValidationsStructure.diseases.suffer_from_disease.required.message, requiredIf(false)),
                        alphaNum: helpers.withMessage("Formato inválido", function (value, index) {
                            if (index.no_int_address_of_contracting.length > 0 ) {
                                return new RegExp(/^[0-9a-zA-ZñÑÀ-ÿ\- ]+$/).test(index.no_int_applicant_address);

                            }
                            return true;
                        })
                    },
                    postal_code_address_of_contracting:{
                        required: helpers.withMessage(ValidationsStructure.diseases.suffer_from_disease.required.message, requiredIf(function (value, index){
                            console.log(index.postal_code_address_of_contracting)
                            if(index.contractor_other_than_the_applicant == "si"){
                                return true;
                            }

                            return false;
                        })),
                        minLength: helpers.withMessage("El campo debe contener mínimo 5 dígitos.", minLength(5)),
                        maxLength: helpers.withMessage("El campo debe contener máximo 5 dígitos.", maxLength(5)),
                        numeric: helpers.withMessage("Solo se permiten números enteros", numeric )
                    }
                }   

                model = {
                    suburb_address_of_contracting:"",
                    street_address_of_contracting: "",
                    no_ext_address_of_contracting:"",
                    no_int_address_of_contracting: "",
                    postal_code_address_of_contracting:0
                }  
            }

            if (category == 'labor_data_of_the_contractor') {
                validations = {
                    company_where_you_work_Labor_data_of_the_contractor: {
                        required: helpers.withMessage(ValidationsStructure.profession_occupation.occupation.required.message, requiredIf(true)),
                        minLength: helpers.withMessage("Se permiten mínimo dos caracteres", minLength(2)),
                        alpha: helpers.withMessage("Solo se permiten letras", function (value, index) {                 
                            if (index.company_where_you_work_Labor_data_of_the_contractor.length > 0 ) {
                                return new RegExp(/^[a-zA-ZñÑÀ-ÿ ]+$/).test(index.company_where_you_work_Labor_data_of_the_contractor);

                            }
                            return true;
                        })                                  
                    },
                    occupation_Labor_data_of_the_contractor: {
                        required: helpers.withMessage(ValidationsStructure.profession_occupation.occupation_list.required.message, requiredIf(true)),
                        minLength: helpers.withMessage("Se permiten mínimo dos caracteres", minLength(2)),
                         alpha: helpers.withMessage("Solo se permiten letras", function (value, index) {                 
                            if (index.occupation_Labor_data_of_the_contractor.length > 0 ) {
                                return new RegExp(/^[a-zA-ZñÑÀ-ÿ ]+$/).test(index.occupation_Labor_data_of_the_contractor);

                            }
                            return true;
                        }) 
                    },
                    annual_income_Labor_data_of_the_contractor: {
                        required: helpers.withMessage(ValidationsStructure.profession_occupation.occupation_list.required.message, requiredIf(true)),
                        numeric: helpers.withMessage("Solo se admiten números", numeric)
                    }
                }

                model = {
                    company_where_you_work_Labor_data_of_the_contractor: "",
                    occupation_Labor_data_of_the_contractor: "",
                    annual_income_Labor_data_of_the_contractor: ""
                }
            }

            if ( category == "applicant_address") {
                validations = {   
                    postal_code_applicant_address: {
                        required: helpers.withMessage(ValidationsStructure.operations.operations_list.required.message, requiredIf(true)),
                        minLength: helpers.withMessage("El campo debe contener mínimo 5 dígitos.", minLength(5)),
                        maxLength: helpers.withMessage("El campo debe contener máximo 5 dígitos.", maxLength(5)),
                        numeric: helpers.withMessage("Solo se permiten números enteros", numeric )
                    }, 
                    suburb_applicant_address: {
                        required: helpers.withMessage(ValidationsStructure.operations.operations.required.message, requiredIf(true))
                    },
                    street_applicant_address: {
                        required: helpers.withMessage(ValidationsStructure.operations.operations.required.message, requiredIf(true)),
                        minLength: helpers.withMessage("El campo debe contener mínimo 2 caracteres.", minLength(2)),
                        maxLength: helpers.withMessage("El campo debe contener máximo 100 caracteres.", maxLength(100)),
                    },
                    no_ext_applicant_address: {
                        required: helpers.withMessage(ValidationsStructure.operations.operations.required.message, requiredIf(true)),
                        minLength: helpers.withMessage("El campo debe contener mínimo 1 dígito.", minLength(1)),
                        maxLength: helpers.withMessage("El campo debe contener máximo 5 dígitos.", maxLength(5)),
                        numeric: helpers.withMessage("Solo se permiten números enteros", numeric )
                    },
                    no_int_applicant_address: {
                        required: helpers.withMessage(ValidationsStructure.operations.operations.required.message, requiredIf(false)),
                        alphaNum:helpers.withMessage("Formato inválido", function (value, index) {
                            if (index.no_int_applicant_address.length > 0 ) {
                                return new RegExp(/^[0-9a-zA-ZñÑÀ-ÿ\- ]+$/).test(index.no_int_applicant_address)

                            }

                            return true;
                        })
                    }
                }

                model = {
                    rfc_applicant_address: "",
                    postal_code_applicant_address: "",
                    suburb_applicant_address: "",
                    street_applicant_address: "",
                    no_ext_applicant_address: "",
                    no_int_applicant_address: ""
                }   
            }

            if ( category == "applicants_employment_data") {
                validations = {   
                    company_where_you_work_applicants_employment_data: {
                        required: helpers.withMessage(ValidationsStructure.hobbies.have_hobbies.required.message, requiredIf(true)),
                        minLength: helpers.withMessage("Se permiten mínimo dos caracteres", minLength(2)),
                        alpha: helpers.withMessage("Solo se permiten letras", function (value, index) {
                            if (index.company_where_you_work_applicants_employment_data.length > 0 ) {
                                return new RegExp(/^[a-zA-ZñÑÀ-ÿ ]+$/).test(index.company_where_you_work_applicants_employment_data);

                            }
                            return true;
                        })
                    },
                    occupation_applicants_employment_data: {
                        required: helpers.withMessage(ValidationsStructure.hobbies.hobbies_list.required.message, requiredIf(true)),
                        minLength: helpers.withMessage("Se permiten mínimo dos caracteres", minLength(2)),
                        alpha: helpers.withMessage("Solo se permiten letras", function (value, index) {
                            if (index.occupation_applicants_employment_data.length > 0 ) {
                                return new RegExp(/^[a-zA-ZñÑÀ-ÿ ]+$/).test(index.occupation_applicants_employment_data);

                            }
                            return true;
                        } )
                    }, 
                    annual_income_applicants_employment_data: {
                        required: helpers.withMessage(ValidationsStructure.hobbies.hobbies.required.message, requiredIf(true)),
                        numeric: helpers.withMessage("Solo se admiten números", numeric)
                    }
                }

                model = {
                    company_where_you_work_applicants_employment_data: "",
                    occupation_applicants_employment_data: "",
                    annual_income_applicants_employment_data: ""
                }   
            }

            if (category == "collection") {
                validations = {   
                    payment_method_collection: {
                        required: helpers.withMessage(ValidationsStructure.smoke.smoke_tobacco.required.message, requiredIf(false)),
                    },
                    frecuency_collection: {
                        required: helpers.withMessage(ValidationsStructure.smoke.tobacco_types.required.message, requiredIf(false))
                    }
                }

                model = {
                    payment_method_collection: "",
                    frecuency_collection: ""
                } 
            }

            if (category == "documents") {
                  validations = {   
                    ine_if_on_both_sides_documents : {
                        required: helpers.withMessage(ValidationsStructure.alcohol.consume_alcoholic.required.message, requiredIf(function(){
                            return true
                        })),
                    },
                    proof_of_address_documents: {
                        required: helpers.withMessage(ValidationsStructure.alcohol.alcohol_type_list.required.message, requiredIf(function(){
                            return false
                        })),
                    }
                }

                model = {
                    ine_if_on_both_sides_documents: "",
                    proof_of_address_documents: ""
                } 
            }

            if (category == "beneficiaries") {
                validations = {
                    name_beneficiaries: {
                        required: helpers.withMessage(ValidationsStructure.coverages.added_benefit.required.message, requiredIf(true)),
                        minLength: helpers.withMessage("El campo debe contener mínimo 2 caracteres.", minLength(2)),
                        maxLength: helpers.withMessage("El campo debe contener máximo 50 caracteres.", maxLength(50)),
                        alpha: helpers.withMessage("Solo se admiten letras", function (data, index) {
                            if (index.name_beneficiaries != "") {
                                return new RegExp(/^[a-zA-ZñÑÀ-ÿ\- ]+$/).test(index.name_beneficiaries);
                            }

                            return true;
                             
                        }),
                    },
                    paternal_name_beneficiaries: {
                        required: helpers.withMessage(ValidationsStructure.coverages.added_benefit.required.message, requiredIf(true)),
                        minLength: helpers.withMessage("El campo debe contener mínimo 2 caracteres.", minLength(2)),
                        maxLength: helpers.withMessage("El campo debe contener máximo 50 caracteres.", maxLength(50)),
                        alpha: helpers.withMessage("Solo se permiten letras", function (value, index) {
                            if (index.paternal_name_beneficiaries.length > 0 ) {
                                return new RegExp(/^[a-zA-ZñÑÀ-ÿ\- ]+$/).test(index.paternal_name_beneficiaries);
                            }
                            return true;
                        })
                    },
                    maternal_name_beneficiaries: {
                        required: helpers.withMessage(ValidationsStructure.coverages.added_benefit.required.message, requiredIf(false)),
                        minLength: helpers.withMessage("El campo debe contener mínimo 2 caracteres.", minLength(2)),
                        maxLength: helpers.withMessage("El campo debe contener máximo 50 caracteres.", maxLength(50)), 
                        alpha: helpers.withMessage("Solo se permiten letras", function (value, index) {
                            if (index.maternal_name_beneficiaries.length > 0 ) {
                                return new RegExp(/^[a-zA-ZñÑÀ-ÿ\- ]+$/).test(index.maternal_name_beneficiaries);
                            }
                            return true;
                        })
                    },
                    birthdate_beneficiaries: {
                        required: helpers.withMessage(ValidationsStructure.coverages.added_benefit.required.message, requiredIf(function(){
                          return  true;
                        })),
                        minLength: helpers.withMessage("El campo debe contener mínimo 2 caracteres.", minLength(2)),
                        isParentGrandParentRelationship: helpers.withMessage("La fecha de nacimiento del beneficiario: madre, padre ó abuelo(a) no puede ser mayor al solicitante", function (value, index ){
                            const quote = getStorage('quote-data')
                            const birthdateApplicant = moment(quote.contracting_data.birthdate_contracting_data, 'DD/MM/YYYY').format('YYYY-MM-DD');
                            const birthdateBeneficiary = moment(index.birthdate_beneficiaries, 'DD/MM/YYYY').format("YYYY-MM-DD");
                            const relationshipBeneficiaries = index.relationship_beneficiaries;
                            const isValid = Date.parse(birthdateBeneficiary) < Date.parse(birthdateApplicant);

                            if (index.birthdate_beneficiaries != "" && relationshipBeneficiaries != "") {
                                if ( relationshipBeneficiaries == "Padre") return isValid;  
                                if ( relationshipBeneficiaries == "Madre" ) return isValid;
                                if ( relationshipBeneficiaries == "Abuelo(a)" ) return isValid; 
                            }
                            
                            return true;
                        }),
                        isChildGrandsonGreatrgandsonParentRelationship: helpers.withMessage("La fecha de nacimiento del beneficiario: hijo(a), nieto(a) ó bisnieto(a) no puede ser menor al solicitante", function (value, index ){
                            const quote = getStorage('quote-data')
                            const birthdateApplicant = moment(quote.contracting_data.birthdate_contracting_data, 'DD/MM/YYYY').format('YYYY-MM-DD');
                            const birthdateBeneficiary = moment(index.birthdate_beneficiaries, 'DD/MM/YYYY').format("YYYY-MM-DD");
                            const relationshipBeneficiaries = index.relationship_beneficiaries;
                            const isValid = Date.parse(birthdateBeneficiary) > Date.parse(birthdateApplicant);

                            if (index.birthdate_beneficiaries != "" && relationshipBeneficiaries != "") {
                                if ( relationshipBeneficiaries == "Hijo(a)") return isValid;  
                                if ( relationshipBeneficiaries == "Nieto(a)" ) return isValid;
                                if ( relationshipBeneficiaries == "Bisnieto(a)" ) return isValid; 
                            }

                            return true;
                        })
                    },
                    relationship_beneficiaries: {
                        required: helpers.withMessage(ValidationsStructure.coverages.added_benefit.required.message, requiredIf(function(){
                          return  true;
                        }))
                    },
                    percentage_beneficiaries: {
                        required: helpers.withMessage(ValidationsStructure.coverages.added_benefit.required.message, requiredIf(function(){
                          return  true;
                        })),
                        numberFormat: helpers.withMessage("No se permiten más de dos dígitos después del punto", function (data, index) {
                            return new RegExp(/^[0-9]+([.][0-9]{1,2})?$/).test(index.percentage_beneficiaries.toString());
                        }),
                        maxValue: helpers.withMessage("El porcetaje máximo admitido es 100 %", function(value, index){
                            let  percentage = Number(index.percentage_beneficiaries);
                            if( percentage > 100){
                                return false;
                            }
                            return true;
                        }),
                        minValue: helpers.withMessage("Porcentaje inválido debe ser mayor a cero, sin valores negativos", function(value, index){
                            let  percentage = Number(index.percentage_beneficiaries);
                            if( percentage <= 0){
                                return false;
                            }
                            return true;
                        }),
                    }
                }

                model = {
                    name_beneficiaries:"",
                    paternal_name_beneficiaries: "",
                    maternal_name_beneficiaries:"",
                    birthdate_beneficiaries:"",
                    relationship_beneficiaries:"",
                    percentage_beneficiaries: 0
                }
            }


            vidaSimpleValidations.value = validations;
            vidaSimpleModel.value = model;
        }

        const getColonies = async(value) => {
            let colonies = await addressClass.value.getColonies(value).then(response => response.data).catch(err => err);
            questionsList.value.forEach(row => {
                if(row.node_name == "suburb_address_of_contracting" || row.node_name == "suburb_applicant_address"){
                    row.options = colonies.colonias.map(col => {
                        return {
                            "option": col.nombre,
                            "testing_question_id": "select_colonies_"+ col.clave,
                            "name_option": col.nombre,
                            "option_id": col.id,
                            "isActive": false,
                            "activeClass":"active"
                            
                        };
                    })
                }
            })

            reRender.value = !reRender.value;
            return colonies;
        }

        const validateRfcStructure = (rfc) => {
            let rfc_pattern =
                "^(([A-ZÑ&]{4})([0-9]{2})([0][13578]|[1][02])(([0][1-9]|[12][\\d])|[3][01])([A-Z0-9]{3}))|" +
                "(([A-ZÑ&]{4})([0-9]{2})([0][13456789]|[1][012])(([0][1-9]|[12][\\d])|[3][0])([A-Z0-9]{3}))|" +
                "(([A-ZÑ&]{4})([02468][048]|[13579][26])[0][2]([0][1-9]|[12][\\d])([A-Z0-9]{3}))|" +
                "(([A-ZÑ&]{4})([0-9]{2})[0][2]([0][1-9]|[1][0-9]|[2][0-8])([A-Z0-9]{3}))$";
            const coincidences = rfc.match(rfc_pattern);
            
            const data = [];
            if(coincidences!=null){
                coincidences.forEach(element => {
                if(typeof element != 'undefined'){
                    data.push(element);
                }
                });
            }
            return coincidences == null ? {'status': false, 'coincidences': data} : {'status': true, 'coincidences': data};
        }

        const loadStorageBeneficiaries = () => {
            try {
                let exist = storageExist('issuance-data');
                if(!exist){
                    throw 'No existe información de emisión en storage';
                }
                
                let beneficiaries = getStorage('issuance-data');
                if(!beneficiaries['beneficiaries-list']){
                    throw 'No existe información de beneficiarios en storage';
                }

                beneficiariesList.value = beneficiaries['beneficiaries-list'];

                return {
                    status: true,
                    message: 'Información recuperada'
                };
            }catch(err){
                return {
                    status: false,
                    message: err
                };
            }
        }

        const isValidToAddBeneficiaries = () => {
            try {
                if (!isEmptyObject(userInfo.value) ) {
                    const loadStorage = loadStorageBeneficiaries();
                    if(!loadStorage.status){
                        const storage = getStorage('issuance-data');
                        storage['beneficiaries-list'] = [];
                        createStorage('issuance-data', storage);
                    }

                    if(userInfo.value.percentage_beneficiaries == 0){
                        throw 'El porcentaje del beneficiario debe ser mayor a 0';
                    }
                    
                    let total = 0;
                    beneficiariesList.value.forEach(beneficiary=>{
                        total = total + beneficiary.percentage_beneficiaries;
                    });
                    
                    let expectedPercentage = total+userInfo.value.percentage_beneficiaries
                    if(expectedPercentage > 100){
                        throw 'El porcentaje asignado a los beneficiarios es mayor a 100%, verifique que la proporción del beneficio sea correcta';
                    }

                    // console.log("modelo: ",vidaSimpleModel.value);
                    vidaSimpleModel.value.name_beneficiaries = 'Melisa';
                    reRender.value = !reRender.value;

                    return {
                            status: true,
                            message: 'formulario valido'
                    }
                }
            }catch(err){
                return {
                    status: false,
                    message: err
                };
            }
        }

        const porcentageComplete = () => {
            
            try {
                let total = 0;
                beneficiariesList.value.forEach(beneficiary=>{
                    total = total + beneficiary.percentage_beneficiaries;
                });

                if(total < 100){
                    throw 'El porcentaje asignado a los beneficiarios debe ser igual a 100%, verifique que la proporción del beneficio sea correcta';
                }

                return {
                        status: true,
                        data: total,
                        message: 'Se ha completado el total del porcentaje permitido'
                }
            } catch(err) {
                let total = 0;
                total = total+userInfo.value.percentage_beneficiaries
                return {
                        status: false,
                        data: total,
                        message: err
                }
            }
        }

        const addBeneficiaryList = () => {
            let isValidFormBeneficiaries = isValidToAddBeneficiaries();
            if(!isValidFormBeneficiaries.status){
                Swal.fire({
                    title: "Aviso",
                    html: isValidFormBeneficiaries.message,
                    icon: "info",
                    confirmButtonText: "OK",
                    confirmButtonColor: '#FEB72B'
                });
                return;
            }
            
            let newBeneficiary = {
                birthdate_beneficiaries: userInfo.value.birthdate_beneficiaries,
                maternal_name_beneficiaries: userInfo.value.maternal_name_beneficiaries,
                name_beneficiaries: userInfo.value.name_beneficiaries,
                paternal_name_beneficiaries: userInfo.value.paternal_name_beneficiaries,
                percentage_beneficiaries: userInfo.value.percentage_beneficiaries,
                relationship_beneficiaries: userInfo.value.relationship_beneficiaries
            };
            beneficiariesList.value.push(newBeneficiary);

            let issuanceData = getStorage('issuance-data');
            issuanceData['beneficiaries-list'] = beneficiariesList.value;
            createStorage('issuance-data', issuanceData);

            let percentageCompleted = porcentageComplete();
            isActive.value = (percentageCompleted.status)? true: false;
            btnAddActive.value = (percentageCompleted.status)? false: true;
        }

        const deleteBeneficiaryList = (position) => {
            let loadStorage = loadStorageBeneficiaries();
            if(!loadStorage.status){
                const storage = getStorage('issuance-data');
                storage['beneficiaries-list'] = [];
                createStorage('issuance-data', storage);
                return;
            }

            beneficiariesList.value = beneficiariesList.value.filter( function( e, index ) {
                return index !== position;
            } );

            let issuanceData = getStorage('issuance-data');
            issuanceData['beneficiaries-list'] = beneficiariesList.value;
            createStorage('issuance-data', issuanceData);
            
            let percentageCompleted = porcentageComplete();
            isActive.value = (percentageCompleted.status)? true: false;
            btnAddActive.value = (percentageCompleted.status)? false: true;
        }

        const searchCatalogInQuestion = async (listQuestion) => {
            if(listQuestion.depend_on_response!=""){
                await searchInCatalog(listQuestion)
            }

            if(listQuestion.depend_on_response == "" && listQuestion.question_relationship_id == 0){
                await searchInCatalog(listQuestion);
            }
        }

        const searchInCatalog = async (listQuestion) => {
            try{
                isLoading.value = true;
                const res = await get(`${listQuestion.endpoints_catalog[0].endpoint}`, { headers: {"Authorization" : `Token ${tokenAuthorization.value}`}, params:  listQuestion.endpoints_catalog[0].json_params.body}).then((response) => response);
                if(res.status == 200){
                    if(!res.data.response.has_errors){
                        listQuestion.options = res.data.response.data.map((response, index)=>{
                            return {
                                "option": response.name,
                                "status": "Activo",
                                "testing_question_id": `${listQuestion.question_type}_${currentCategory.value}_${index}`,
                                "name_option": response.name,
                                "option_id": `${listQuestion.question_type}_${currentCategory.value}_${index}`,
                                "isActive": false,
                                "activeClass": "active"
                            }
                        });
                    }
                }
                isLoading.value = false;
            }
            catch(ex){
                console.log(ex);
                isLoading.value = false;
                Swal.fire({
                    icon:'error',
                    title:"Cuidado!",
                    text:"Ocurrió un error inesperado."
                }).then((res)=>{
                    if(res){
                        router.push({ path: "/cuenta/principal" });
                    }
                })
            }
        }

        return {
            listOfQuestionPeronalInfo: questionsList,
            changeModelPersonalData,
            next,
            isLoading,
            validations,
            vidaSimpleModel,
            reRender,
            currentCategory,
            ComponentsPersonalDataRef,
            isActive,
            questionTitle,
            imageCategory,
            getColonies,
            validateRfcStructure,
            addBeneficiaryList,
            deleteBeneficiaryList,
            beneficiariesList,
            btnAddActive,
            createStorage,
            searchCatalogInQuestion
        }
    },
    watch: {
        'vidaSimpleModel.postal_code_address_of_contracting': {
            handler(value) {
                if (value !== undefined && value.toString().length == 5) {

                    this.getColonies(value.toString()).then(colonies => {

                        if (colonies != []) {

                            let location = {
                                state: colonies.estado.nombre,
                                municipality: colonies.municipio.nombre,
                                city: colonies.ciudad.nombre != null ? colonies.ciudad.nombre : 'N/A'
                            }
                            this.createStorage('issuance-address-of-contracting', location);
                        }

                    });
                }
            },
            deep: true
        },
        'vidaSimpleModel.postal_code_applicant_address': {
            handler(value) {
                if (value !== undefined && value.toString().length == 5) {

                    this.getColonies(value.toString()).then(colonies => {
                        if (colonies != []) {

                            let location = {
                                state: colonies.estado.nombre,
                                municipality: colonies.municipio.nombre,
                                city: colonies.ciudad.nombre != null ? colonies.ciudad.nombre : 'N/A'
                            }

                            this.createStorage('issuance-applicant-address', location);
                        }
                    });
                }
            },
            deep: true
        },
        'vidaSimpleModel.rfc_applicant_address':{
            handler(value){
                if(value !== undefined && value.toString().length >= 12 && value.toString().length < 14){
                    const rfc = this.validateRfcStructure(value.toString());
                }
                
            },
            deep: true
        },
        listOfQuestionPeronalInfo:{
            async handler(value){
                for(const question of value){
                    if(question.question_type=="select" && question.endpoints_catalog.length>0 && question.showQuestion && question.options.length==0){
                        await this.searchCatalogInQuestion(question);
                    }
                }
            },
            deep:true
        }
    },
    components:{
        ComponentsPersonalData,
        Loading,
        BeneficiariesDetail
    }

}
</script>
<style>
.question-container > div > div {
    justify-content: space-between;
    width: 100%;
    margin-top: 5px;
    margin-bottom: 15px;
}

.question-container > div > div > button{
    border-radius:5px;
    width:49%;
}

.question-container>div>label, .question-container>div>input, .question-container>div>button {
     font-family: "roboto-regular";
}

.question-container > div > div > button.active {
    background-color: #810042;
    color: white;
    border-color: #810042;
}

.question-container > div > div > button {
    box-sizing: border-box;
    background: #D9D9D9;
    color: white;
    border-radius: 5px;
    font-size: 18px;
    margin-inline: 1px;
}

.question-container>div>label {
    font-size: 18px;
    line-height: 30px;
    color: #485462;
}

.card-shadow {
  box-shadow: 0px 4px 6px -3px rgb(178 176 176);
  -webkit-box-shadow: 0px 4px 6px -3px rgb(178 176 176);
  -moz-box-shadow: 0px 4px 6px -3px rgb(178 176 176);
}

.content_textarea_0 {
    margin-top: 20px;
}

.content_date_3, .content_date_4 {
    margin-top: 9px;
}

.vpd-input-group input {
    flex-grow: 1;
    border: 2px solid #e5e7eb;
    border-right: 2px solid #e5e7eb;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    line-height: 30px;
    padding: 0 10px;
    height: 43px;
}

.content_textarea_6 {
    display:flex;
}

.content_checkbox_1{
    display: block;
}

@media only screen and (min-width: 600px) {
    .content_date_3, .content_date_4 {
        display: block;
    }
}

@media only screen and (max-width: 600px) {
    .content_date_3, .content_date_4 {
        display: flex;
    }
}
</style>
<style scoped>
.button-disabled {
    background-color: #EDD191;
}

@media only screen and (min-width: 600px) {
    .detail-container {
        background-color: #FAFAFA;
    }
    .content_date_3, .content_date_4 {
        display: block;
    }
}
@media only screen and (max-width: 600px) {
    .bg-container {
        background-color: #FCFCFC;
    } 
    .content_date_3, .content_date_4 {
        display: flex;
    }
}

[type*="text"] {
    text-transform: uppercase !important;
}
</style>
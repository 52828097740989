const host = process.env.VUE_APP_CCAPI
const business = process.env.VUE_APP_BUSINESS_FONCABSA
import api from '@/api/api'

export default class Products {

    getVsQuestions = async (token) => {

        const resource = `/${business}/vs_questions/list_life_simple`;
        const API_URL = `${host}${resource}`;
        const config = {
            headers: {
                "Content-Type": "application/json",
            }
        };

        const params = {
            token_auth: token,
            product_name: 'Vida simple'
        };

        return await api.httpGet(API_URL, { config }, params);
    }
    
    getQuotingList = async (token) => {
        const resource = `/${business}/vs_questions/quoting_list_simple_life`;
        const API_URL = `${host}${resource}`;
        const config = {
            headers: {
                "Content-Type": "application/json",
            }
        };

        const params = {
            token_auth: token,
            product_name: 'Vida simple'
        };

        return await api.httpGet(API_URL, { config }, params);
    }

    getVsAdditionalInsuanceQuestions = async (token) => {
        const resource = `/${business}/vs_questions/quoting_list_simple_life_additional_insured`;
        const API_URL = `${host}${resource}`;
        const config = {
            headers: {
                "Content-Type": "application/json",
            }
        };

        const params = {
            token_auth: token,
            product_name: 'Vida simple'
        };

        return await api.httpGet(API_URL, { config }, params);
    }
}
export default class Response {
    
    status = "";
    message = "";
    data = "";
    #data_structure = "";

    constructor({ data })
    {
        this.#data_structure = data; 
    }

    validate()
    {
        this.status = true;
        this.data = []

        const node_response = this.search_node_content_response();
        this.validate_response(node_response);

        if(this.data.has_error || this.data[node_response] == "")  
            this.status = false;
        
            if(this.data == "" )
        {
            this.status = false;
        }
        
        else
            this.status = true;
    }

    assing_messaje()
    {
        this.message = this.#data_structure.response.message;
    }

    search_node_content_response()
    {
        return Object.keys(this.#data_structure.response).includes("result") ? "result" : "response";
    }

    validate_response(node_response)
    {
        if(this.#data_structure.response[node_response] != "" && this.#data_structure.response[node_response] != undefined)
        {
            this.data = this.#data_structure.response[node_response];
            if(this.data == "" || this.data.has_error || this.data[node_response] == "")
                this.assing_messaje();
        }
        else
        {
            // console.log(this.#data_structure.response)
            if(Object.keys(this.#data_structure.response).length > 0 || this.#data_structure.response.has_error == false)
            {
                this.data = this.#data_structure.response;
                if(this.data == "" || this.data.has_error || this.data[node_response] == "")
                this.assing_messaje();
            }
            else
            {
                this.assing_messaje();
            }
        }
    }
}
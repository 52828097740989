<template>
    <div class="sm:full xl:full mt-2">
        <div class="w-full flex justify-center">
            <input v-if="enableAddButton" :class="{'button-disabled cursor-default': !enableAddButton, 'bg-mustardDark cursor-pointer': enableAddButton}" type="button" class="w-full text-white px-5 py-2 mx-auto my-4 h-14 md:h-14  rounded text-center font-medium text-xl" @click="$emit('addBeneficiary')" value="Añadir">       
        </div>
        <div class="w-full flex justify-center mt-8 mb-8">
            <div class="w-full grid grid-cols-2">
                <p class="text-left text-xl font-bold roboto-regular mb-10 text-gray-600">Beneficiarios</p>
                <p class="text-right text-xl font-bold roboto-regular mb-10 text-gray-600">%</p>
                <div v-for="(beneficiary, index) in beneficiaries" :key="index" class="w-full grid grid-cols-2 col-span-2 mb-10">
                    <div class="grid grid-cols-1 text-left">
                        <p class="text-2xl text-gray-500 RobotoLight">{{ `${beneficiary.name_beneficiaries} ${beneficiary.paternal_name_beneficiaries} ${beneficiary.maternal_name_beneficiaries}` }}</p>
                        <small class="text-gray-500 RobotoLight capitalize">{{beneficiary.relationship_beneficiaries}} | {{ getAgeByDate(beneficiary.birthdate_beneficiaries)}} años</small>
                    </div>
                    <div class="grid grid-cols-1 text-right">
                        <p class="text-2xl text-right text-gray-600 RobotoRegular">{{beneficiary.percentage_beneficiaries}}%</p>
                        <button class="text-xs text-right text-red-400 RobotoRegular" @click="$emit('deleteBeneficiary', index)">Eliminar</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import moment from "moment";

export default ({
    name:"BeneficiariesDetail",
    emits: ['addBeneficiary', 'deleteBeneficiary'],
    props: {
        enableAddButton: {
            type: Boolean,
            default: true
        },
        beneficiaries: {
            type: Array,
            default: []
        }
    },
    setup() {

        const getAgeByDate = (date) => {
            date = date.toString().split('/').reverse();
            let birthday = moment(`${date[0]}-${date[1]}-${date[2]}`);
            let today = moment();
            let age = today.diff(birthday,"years");
            return age;
        }

        return {
            getAgeByDate
        }
    }
})

</script>